// PreorderModal.js
import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';

const PreorderModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Check if popup has been shown before
    const hasShownPopup = localStorage.getItem('preOrderPopupShown');
    const endDate = new Date('2025-01-19T00:00:00');
    const currentDate = new Date();

    if (!hasShownPopup && currentDate < endDate) {
      setIsOpen(true);
    }
  }, []);

  const handleIgnore = () => {
    localStorage.setItem('preOrderPopupShown', 'true');
    setIsOpen(false);
  };

  const handlePreOrder = () => {
    window.location.href = 'https://photos.oneglint.com/preorder?eventId=TMM2025';
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleIgnore}
      aria-labelledby="pre-order-modal"
      aria-describedby="pre-order-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: '90%', sm: '450px' },
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 2,
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 3
        }}>
          <Box sx={{
            width: { xs: '120px', sm: '140px' },
            mb: 2
          }}>
            <img
              src="https://sports.oneglint.com/user/images/tmm2025logo.jpg"
              alt="Tata Mumbai Marathon 2025"
              style={{ width: '100%', height: 'auto' }}
            />
          </Box>
          
          <Typography 
            variant="h6" 
            sx={{
              fontFamily: 'Urbanist-Bold',
              textAlign: 'center',
              mb: 1
            }}
          >
            Tata Mumbai Marathon 2025
          </Typography>
          
          <Typography 
            variant="body1" 
            sx={{
              fontFamily: 'Urbanist-Regular',
              fontSize: '16px',
              color: 'text.secondary',
              textAlign: 'center'
            }}
          >
            2025-01-19
          </Typography>
        </Box>

        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 2,
          justifyContent: 'center'
        }}>
          <Button
            variant="contained"
            onClick={handlePreOrder}
            sx={{
              bgcolor: '#3F0E77',
              color: 'white',
              fontFamily: 'Urbanist-Regular',
              fontSize: '14px',
              '&:hover': {
                bgcolor: '#2F0A5A',
              },
              width: { xs: '100%', sm: 'auto' },
              minWidth: { sm: '120px' }
            }}
          >
            Pre Order
          </Button>
          
          <Button
            variant="outlined"
            onClick={handleIgnore}
            sx={{
              borderColor: '#E0E0E0',
              color: 'text.primary',
              fontFamily: 'Urbanist-Regular',
              fontSize: '14px',
              fontWeight: 600,
              '&:hover': {
                bgcolor: '#F5F5F5',
                borderColor: '#E0E0E0'
              },
              width: { xs: '100%', sm: 'auto' },
              minWidth: { sm: '120px' }
            }}
          >
            Ignore
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreorderModal;